import { Tooltip } from '@material-ui/core'
// import { Edit } from '@material-ui/icons'
// import { DatePicker } from '@material-ui/pickers'
// import { IconButton } from '@mui/material'
import { format } from 'date-fns'

export const administrationProjectWithoutPdlMapper = (
	projects,
	setOpenConfirmPdl,
	setProjectSales,
	// editDateMaximumPurchase,
	// createRef,
	// setOpenEditPdlAllowable,
) => {
	return projects.map((item) => {
		// const dateRef = createRef()
		const numberFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })

		return {
			id: item?._id,
			projectAddress: `${item?.boe?.location}, ${item?.boe?.number}, ${item?.boe?.floor}, ${item?.boe?.door}`,
			maxSaleDate: item?.offerAccepted?.dateMaximumPurchase ? (
				<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
					<span>{format(new Date(item?.offerAccepted?.dateMaximumPurchase), 'dd/MM/yyyy')}</span>
					{/* <IconButton color='success' size='small' onClick={() => dateRef.current.click()}>
						<Edit />
					</IconButton>
					<DatePicker
						value={item?.offerAccepted?.dateMaximumPurchase}
						inputRef={dateRef}
						onChange={(e) => {
							editDateMaximumPurchase({
								id: item._id,
								offerId: item?.offerAccepted._id,
								dateMaximumPurchase: new Date(e),
							})
						}}
						label=''
						style={{
							display: 'none',
						}}
					/> */}
				</div>
			) : (
				'Sin fecha'
			),
			pdlAllowable: item.offerAccepted ? (
				<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
					<span>{numberFormat.format(item.pdlAllowable)}</span>
					{/* <IconButton
						color='success'
						size='small'
						onClick={() => {
							setOpenEditPdlAllowable(true)
							setProjectSales(item)
						}}
					>
						<Edit />
					</IconButton> */}
				</div>
			) : (
				<Tooltip title='Este proyecto no tiene una oferta de compra aceptada en el SIG'>
					<span>Sin oferta aceptada en SIG</span>
				</Tooltip>
			),
			totalPdl: item?.offerAccepted ? (
				numberFormat.format(item.totalPdl)
			) : (
				<Tooltip title='Este proyecto no tiene una oferta de compra aceptada en el SIG'>
					<span>Sin oferta aceptada en SIG</span>
				</Tooltip>
			),
			pdlAvailable: item.offerAccepted ? (
				numberFormat.format(item.pdlAvailable)
			) : (
				<Tooltip title='Este proyecto no tiene una oferta de compra aceptada en el SIG'>
					<span>Sin oferta aceptada en SIG</span>
				</Tooltip>
			),
			actions: item?.offerAccepted && (
				<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='actions-right'>
					<div
						onClick={() => [setProjectSales(item), setOpenConfirmPdl(true)]}
						style={{
							cursor: 'pointer',
							color: '#4646c3',
							padding: '10px',
							width: '120px',
							textAlign: 'end',
						}}
					>
						<b>Hacer PDL</b>
					</div>
				</div>
			),
		}
	})
}

export const administrationProjectWithPdlMapper = (projects) => {
	return projects.map((item) => {
		return {
			id: item.project._id,
			projectAddress: `${item.project.boe.location}, ${item.project.boe.number}, ${item.project.boe.floor}, ${item.project.boe.door}`,
			profile: item?.profileInvestor ? item?.profileInvestor?.businessName : 'Sin Nombre',
			import:
				item?.amountPdl && item?.amountPdl
					? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item?.amountPdl)
					: '0,00 €',
			date: item?.date ? format(new Date(item?.date), 'dd/MM/yyyy - hh:mm aaa') : 'Sin fecha',
			user: item?.user ? item?.user?.name : 'Sin Nombre',
			typeOfInterest: item?.typeOfInterest ? item.typeOfInterest : '0%',
			actions: '',
		}
	})
}
